
import './css/main.css';

import barba from '@barba/core';

import { gsap } from "gsap";

// import { defineCustomElements } from 'vidstack/elements';


barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        // hamburgerToggle('c');
        return gsap.to(data.current.container, {
          opacity: 0
        });

        
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });

        
        
        
      },
      once(data) {
        gradients();
        
      },
      afterOnce(data) {
      }
    }],
});


  // function hamburgerToggle(preset) {
  //   import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
  //     const toggle = module.default;
  //     toggle(preset);
  //   });
  // }

  // let hamburgerOuter = document.getElementById("hamburgerOuter");
  // hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});


  function gradients(preset) {
      import(/* webpackChunkName: "gradients" */ "./js/components/gradients.js").then(module => {
        const gradientGo = module.default;
        gradientGo(preset);
      });
  }

  function scrollToTop() {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    window.scrollTo({top: 0});
  };

  barba.hooks.beforeEnter((data) => {
    scrollToTop();
  });

  barba.hooks.after((data) => {
    gradients();
  });


  barba.hooks.afterEnter((data) => {
  });